<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="status" v-model.lazy="data.nama_mahasiswa_dts" />
                                    <label for="status">Nama Mahasiswa</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="status" v-model.lazy="data.NIM_DTS" />
                                    <label for="status">NIM</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="status" v-model.lazy="data.lunas" :options="option_status" optionLabel="text" optionValue="value" />
                                    <label for="status">Status Tagihan</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                    <label for="prodi_id">Prodi</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="tahun" v-model.lazy="data.tahun_kb" :options="tahun" optionLabel="text" optionValue="value" />
                                    <label for="tahun">Tahun</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="semester" v-model.lazy="data.semester" :options="option_semester" optionLabel="text" optionValue="value" />
                                    <label for="semester">Semester</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid"></div>
                        <div class="grid mt-4">
                            <div class="col-6">
                                <!-- <div class="grid">
                                    <div class="col-4">Total Piutang</div>
                                    <div class="col-6 text-right">{{ $formats(totalPiutang).format() }}</div>
                                </div>
                                <div class="grid">
                                    <div class="col-4">Total Tagihan</div>
                                    <div class="col-6 text-right">{{ $formats(total).format() }}</div>
                                </div> -->
                            </div>
                            <div class="col-6 text-right">
                                <div class="grid p-fluid">
                                    <div class="col-4">
                                        <Button class="p-button p-button-success" block label="Search" @click="get_rekap()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                                    </div>
                                    <!-- <div class="col-4">
                                      <Button class="p-button p-button-warning" label="Excel" @click="downloadExcel()" />
                                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <h5>Laporan GAKIN</h5>
                        <DataTable
                            :value="rekap"
                            class="p-datatable-gridlines"
                            :rows="perPage"
                            v-model:filters="filters1"
                            dataKey="id"
                            :totalRecords="totalRows"
                            :paginator="true"
                            :rowHover="true"
                            filterDisplay="menu"
                            :loading="loading1"
                            :filters="filters1"
                            responsiveLayout="scroll"
                            :globalFilterFields="field"
                            @page="onPage($event)"
                        >
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">
                                    <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="data.jumlah" @change="set_jumlah($event)" /> Per Halaman dari {{ totalRows }} data</div>
                                    <div class="filter">
                                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #empty> Tidak ada data. </template>
                            <template #loading> Loading data. mohon tunggu.... </template>
                            <Column field="no" header="No" style="width: 50px">
                                <template #body="{ data }">
                                    {{ data.no }}
                                </template>
                            </Column>
                            <Column field="nama_mahasiswa_dts" header="Nama Mahasiswa" style="min-width: 10rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_mahasiswa_dts }}
                                </template>
                            </Column>
                            <Column field="NIM_DTS" header="NIM" style="min-width: 10rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.NIM_DTS }}
                                </template>
                            </Column>
                            <Column field="nama_prodi" header="Nama Prodi" style="min-width: 6rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_prodi }}
                                </template>
                            </Column>
                            <Column field="tagihan" header="Tagihan" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.tagihan).format() }}
                                </template>
                            </Column>
                            <Column field="nominal_beasiswa" header="Nominal Beasiswa" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.nominal_beasiswa).format() }}
                                </template>
                            </Column>
                            <Column field="tahun_ak" header="Angkatan" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }"> {{ data.tahun_ak }}</template>
                            </Column>
                            <Column field="nama_kategori" header="Semester" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }"> {{ data.nama_kategori }} </template>
                            </Column>
                            <Column field="status_pembayaran" header="Status" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    <Button v-if="data.status_pembayaran == 'LUNAS'" class="p-button-text p-button-success mr-2"> {{ data.status_pembayaran }}</Button>
                                    <Button v-else-if="data.status_pembayaran == 'DISPENSASI'" class="p-button-text p-button-warning mr-2"> {{ data.status_pembayaran }}</Button>
                                    <Button v-else-if="data.status_pembayaran == 'BELUM LUNAS'" class="p-button-text p-button-danger mr-2"> {{ data.status_pembayaran }}</Button>
                                </template>
                            </Column>
                            <!-- <Column field="status_pembayaran" header="Persen" style="min-width: 2rem" :sortable="true">
                                <template #body="{ data }"> {{ ((data.terbayar / data.tagihan) * 100).toFixed(2) }} % </template>
                            </Column> -->
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            rekap: [],
            total_tagihan: null,
            field: ['nama_prodi', 'nama_mahasiswa_dts', 'NIM_DTS', 'status_pembayaran'],
            loading1: true,
            prodi: [],
            tahun: [],
            total: 0,
            totalRows: 0,
            option_semester: [
                { text: 'Ganjil', value: 'GANJIL' },
                { text: 'Genap', value: 'GENAP' },
            ],
            option_status: [
                { text: 'Belum Lunas', value: '0' },
                { text: 'Lunas', value: '1' },
            ],
            data: {
                tahun_kb: null,
                status: null,
                prodi_id: null,
                NIM_DTS: null,
                nama_mahasiswa_dts: null,
                jumlah: 10,
                halaman: 1,
                lunas: null,
            },
            busy: false,
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 50,
            perPages: [10, 25, 50, 100],
        };
    },
    mounted() {
        this.get_rekap();
        this.get_data();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.data.halaman = x.page + 1;
            this.get_rekap();
        },
        set_jumlah(x) {
            this.data.jumlah = x.value;
            this.data.halaman = 1;
            this.get_rekap();
        },
        get_semester_tagihan(x) {
            console.log(x, 'ikiki');
            if (x[5] == '1') {
                return x.splice(4, 2, ' Ganjil');
            } else {
                return x.splice(4, 2, ' Genap');
            }
        },
        initFilters1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_rekap() {
            let vm = this;
            vm.loading1 = true;
            vm.busy = true;
            vm.rekap = [];
            vm.$axiosbilling
                .post('laporan_gakin/list', vm.data)
                .then((res) => {
                    console.log(res);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.rekap = res.data.data.map((item, idx) => {
                                item.no = (vm.data.halaman - 1) * vm.data.jumlah + idx + 1;
                                if (item.status_tagihan == 1) {
                                    item.status_pembayaran = 'LUNAS';
                                } else if (item.status_tagihan == 2) {
                                    item.status_pembayaran = 'DISPENSASI';
                                } else {
                                    item.status_pembayaran = 'BELUM LUNAS';
                                }
                                return { ...item };
                            });
                            vm.totalRows = Number(res.data.count);
                        }
                    }
                    vm.loading1 = false;
                    vm.busy = false;
                })
                .catch((err) => {
                    console.log(err);
                    vm.loading1 = false;
                });
        },
        async get_data() {
            let vm = this;

            for (let i = 2022; i < 2035; i++) {
                vm.tahun.push({ value: i, text: i });
            }

            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }
        },
        reset() {
            this.data = {
                tahun_kb: null,
                status: null,
                prodi_id: null,
                NIM_DTS: null,
                nama_mahasiswa_dts: null,
                jumlah: 10,
                halaman: 1,
                lunas: null,
            };
            this.get_rekap();
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
